import React from "react";
import BaseEditAccPage from "../../../Base/ZMU/BaseEditAccPage";
import * as f from "../../../common/Funcs";
import {BaseTextButton} from "../../../Base/BaseButtons";
import * as z from "../../../common/zmuFuncs";
import {toast} from "../../../common/Funcs";

//общая информация
//пользователь видит, только если вводил сам.
const keyName = "PasAllPage";
export default class PasAllPage extends BaseEditAccPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.fieldsSet = [
            {title: 'Основные данные', fieldset: 'route', column: 0, pos: 10},
            {title: 'Треки', fieldset: 'gpx', column: 0, pos: 20},
            {title: 'История изменений', fieldset: 'log', column: 0, pos: 50},
        ];
        context.topMenuButtonNames = ['reload', 'print', 'clipboard', 'frompoint'];
        const apply = ({elements, e, enable}) => {
            const element = elements[e].field?.cmp || elements[e].subPanel;
            const file = elements[e].file?.cmp;
            if (enable) {
                element.enable();
                if (file) file.enable();
            } else {
                element.disable();
                element.setChecked?.(false);
                element.setValue?.(null);
                if (file) {
                    file.disable();
                    file.setValue(null);
                }
            }
        };

        const elementVisible = ({elements, e, visible}) => {
            const element = elements[e].field?.cmp || elements[e].subPanel;
            if (visible) {
                element.show();
            } else {
                element.hide();
            }
        }
        const checkBirds = (event) => {
            const {elements} = event;
            if (elements.use_transport_erase.field.cmp._checked || elements.use_transport.field.cmp._checked) {
                f.toast('При использовании транспортных средств учет фактов встреч на маршруте и расчет численности птиц на исследуемой территории не производится.');
                elements.PointBirdSub.subPanel.hide();
                elements.PassageTable3Sub.subPanel.hide();
            } else {
                elements.PointBirdSub.subPanel.show();
                elements.PassageTable3Sub.subPanel.show();
            }
        };
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {
                pos: 5, row: 10, column_name: 'oktmo', hidden: true,
            },
            {
                pos: 5, row: 10, column_name: 'layout_ea', hidden: true,
            },
            {
                pos: 5, row: 10, column_name: 'passage_id', hidden: true,
            },
            {
                pos: 5, row: 10, column_name: 'route_id', hidden: true,
            },
            {
                pos: 5, row: 10, column_name: 'route_huntuser', hidden: true,
            },
            {
                pos: 10, row: 10, column_name: 'route_name', disabled: true,
            },
            {pos: 20, row: 10, column_name: 'passage_num', disabled: true, width: 150},
            {pos: 30, row: 10, column_name: 'acc_year', disabled: true,},
            {
                pos: 1,
                row: 15,
                column_name: 'use_navigator', disabled: () => context.state.data?.isvalid, defaultValue: false,
                labelAlign: 'left',
                data: [{value: true, title: 'Да'}, {value: false, title: 'Нет'}]
            },
            {
                pos: 2,
                row: 15,
                column_name: 'use_registrator', disabled: () => context.state.data?.isvalid, defaultValue: false,
                labelAlign: 'left',
                data: [{value: true, title: 'Да'}, {value: false, title: 'Нет'}]
            },
            {
                pos: 1,
                row: 16,
                column_label: 'Использование транспортных средств при затирке ',
                column_name: 'use_transport_erase', disabled: () => context.state.data?.isvalid, defaultValue: false,
                labelAlign: 'left',
                onChange: checkBirds
                // data: [{value: true, title: 'Да'}, {value: false, title: 'Нет'}]
            },
            {
                pos: 2,
                row: 16,
                column_label: 'при учете',
                column_name: 'use_transport', disabled: () => context.state.data?.isvalid, defaultValue: false,
                labelAlign: 'left',
                onChange: checkBirds
                // data: [{value: true, title: 'Да'}, {value: false, title: 'Нет'}]
            },
            {
                pos: 160, row: 20, column_name: 'passager_id',
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'ved_contacts',
                    titleField: 'fio',
                    idField: 'contact_id',
                    filterString: "$passage_id$=any(passages)",
                },
                onFocusLeave_disable: (field, value, sender) => {
                    if (value && value.length > 4 && !Number(value)) {
                        f.confirm({title: 'Нет записи.', message: 'Добавить нового проходчика в базу?'}, (result) => {
                            if (result) {
                                let formdata = new FormData();
                                const values = value.split(' ');
                                formdata.append('contact_active', true);
                                formdata.append('id', '-1');
                                formdata.append('family_name', values[0]);
                                formdata.append('first_name', values[1]);
                                formdata.append('second_name', values[2]);
                                const data = {
                                    contact_active: true,
                                    id: '-1',
                                    family_name: values[0],
                                    first_name: values[1],
                                    second_name: values[2]
                                };
                                const submit = (result) => {
                                    f.callRelationSend({
                                        props: {
                                            relation: {
                                                "tableName": "huntusers_contacts",
                                                "parentField": "contact_huntuser",
                                                "relField": "huntuser_contact",
                                                rowId: context.state.data.route_huntuser,
                                                "relValue": [result.contact_id],
                                                "replace": false
                                            },
                                            idName: 'contact_id',
                                            rowId: context.state.data.route_huntuser,
                                        },
                                        callback: (res) => {
                                            const params = {
                                                context: context,
                                                tableName: 'huntusers_contacts',
                                                id: res.data.row_id,
                                                data: {post_in: 'Проходчик', row_id: res.data.row_id},
                                                callback: () => {
                                                    context.elements.passager_id.setState({
                                                        data: [...context.elements.passager_id.state.data,
                                                            {
                                                                value: result.contact_id,
                                                                title: context.elements.passager_id.field.cmp.getValue()
                                                            }]
                                                    }, () =>
                                                        context.elements.passager_id.setValue(result.contact_id));
                                                }
                                            };
                                            f.setObjectData(params);
                                        },
                                        items: [{data: result.data}], replace: false
                                    });
                                };
                                f.setObjectData({
                                    context: context,
                                    tableName: 'contacts',
                                    id: 'null',
                                    values: formdata,
                                    data,
                                    // relation: {
                                    //     "tableName": "huntusers_contacts",
                                    //     "parentField": "contact_huntuser",
                                    //     rowId: context.state.data.route_huntuser,
                                    //     "relField": "huntuser_contact",
                                    //     "replace": false
                                    // },
                                    callback: submit
                                })
                            }
                        })
                    }
                },
                disabled: () => context.state.data?.isvalid

            },
            {
                pos: 40, row: 30, column_name: 'snow_date', className: 'no-copy',
                width: '450px', labelAlign: 'left', disabled: () => context.state.data?.isvalid
            },
            {
                pos: 42,
                row: 30,
                column_name: 'snow_time',
                className: 'no-copy',
                width: '150px',
                labelAlign: 'left',
                column_label: ' ',
                disabled: () => context.state.data?.isvalid
            },
            {
                pos: 150,
                row: 70,
                column_name: 'erase',
                column_label: 'Учет после затирки',
                disabled: () => context.state.data?.isvalid,
                defaultValue: false,
                labelAlign: 'right',
                isNotNull: true,
                onChange: (event) => {
                    const {elements, newValue} = event;
                    if (elements) {
                        Object.keys(elements).map(e => {
                            if (e.indexOf('erase') > -1 && e != 'erase')
                                apply({elements, e, enable: Boolean(newValue)});
                        });
                        if (newValue) elements['next_day'].field.cmp.setChecked(false);
                    }

                }
            },
            {
                pos: 60,
                row: 70,
                column_name: 'erase_date',
                disabled: () => context.state.data?.isvalid || !(context.state.data?.erase),
                width: '270px',
                labelAlign: 'left'
            },
            {
                pos: 70,
                row: 70,
                column_name: 'erase_start',
                disabled: () => context.state.data?.isvalid || !(context.state.data?.erase),
                width: 200,
                data_type: 'time',
                labelAlign: 'left'
            },
            {
                pos: 80,
                row: 70,
                column_name: 'erase_end',
                disabled: () => context.state.data?.isvalid || !(context.state.data?.erase),
                width: 200,
                data_type: 'time',
                labelAlign: 'left'
            },
            // {
            //     pos: 85,
            //     row: 75,
            //     column_name: 'erase_gpx',
            //     labelAlign: 'left',
            //     cls: 'erase-gpx',
            //     column_label: 'трек затирки'
            // },
            // {pos: 86, row: 75, column_name: 'erase_gpx_upload', data_type: 'upload', labelAlign: 'left'},
            {
                pos: 120,
                row: 80,
                column_name: 'snow_height',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                data_type: 'integer'
            },
            {
                pos: 130,
                row: 80,
                column_name: 'snow_type',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
            },
            {
                pos: 140,
                row: 90,
                maxWidth: 100,
                column_name: 'temperature',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                data_type: 'integer'
            },
            {
                pos: 150,
                row: 90,
                column_name: 'precipitation',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
            },
            {
                pos: 150,
                row: 75,
                column_name: 'next_day',
                disabled: () => context.state.data?.isvalid,
                defaultValue: false,
                labelAlign: 'right',
                isNotNull: true,
                onChange: (event) => {
                    const {elements, newValue} = event;
                    if (elements && newValue) {
                        Object.keys(elements).map(e => {
                            if (e.indexOf('erase') > -1 && e != 'erase')
                                apply({elements, e, enable: !Boolean(newValue)});
                        });
                        elements['erase'].field.cmp.setChecked(false);

                    }
                }
            },
            {
                pos: 90,
                row: 75,
                column_name: 'passage_date',
                disabled: () => context.state.data?.isvalid,
                width: '280px',
                labelAlign: 'left'
            },
            {
                pos: 100,
                row: 75,
                column_name: 'passage_start',
                disabled: () => context.state.data?.isvalid,
                width: 200,
                data_type: 'time',
                labelAlign: 'left'
            },
            {
                pos: 110,
                row: 75,
                column_name: 'passage_end',
                disabled: () => context.state.data?.isvalid,
                width: 200,
                data_type: 'time',
                labelAlign: 'left'
            },
            // {
            //     pos: 115,
            //     row: 120,
            //     column_name: 'passage_gpx',
            //     labelAlign: 'left',
            //     cls: 'passage-gpx',
            //     column_label: 'трек учета'
            // },
            // {pos: 116, row: 120, column_name: 'passage_gpx_upload', data_type: 'upload', labelAlign: 'left'},
            {
                pos: 170,
                row: 170,
                column_name: 'filling_date',
                disabled: () => context.state.data?.isvalid,
                width: '280px',
                labelAlign: 'left',
            },
            {
                pos: 175,
                row: 175,
                column_name: 'responsible_id',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'ved_contacts',
                    titleField: 'fio',
                    idField: 'contact_id',
                    filterString: "$passage_id$=any(passages)",
                },
                onFocusLeave_disable: (field, value, sender) => {

                    if (value && value.length > 4 && !Number(value)) {
                        f.confirm({title: 'Нет записи.', message: 'Добавить нового проходчика в базу?'}, (result) => {
                            if (result) {
                                let formdata = new FormData();
                                const values = value.split(' ');
                                formdata.append('contact_active', true);
                                formdata.append('id', '-1');
                                formdata.append('family_name', values[0]);
                                formdata.append('first_name', values[1]);
                                formdata.append('second_name', values[2]);
                                const data = {
                                    contact_active: true,
                                    id: '-1',
                                    family_name: values[0],
                                    first_name: values[1],
                                    second_name: values[2]
                                };
                                const submit = (result) => {
                                    f.callRelationSend({
                                        props: {
                                            relation: {
                                                "tableName": "huntusers_contacts",
                                                "parentField": "contact_huntuser",
                                                "relField": "huntuser_contact",
                                                rowId: context.state.data.route_huntuser,
                                                "relValue": [result.contact_id],
                                                "replace": false
                                            },
                                            idName: 'contact_id',
                                            rowId: context.state.data.route_huntuser,
                                        },
                                        callback: (res) => {
                                            const params = {
                                                context: context,
                                                tableName: 'huntusers_contacts',
                                                id: res.data.row_id,
                                                data: {post_in: 'Проходчик', row_id: res.data.row_id},
                                                callback: () => {
                                                    context.elements.passager_id.setState({
                                                        data: [...context.elements.passager_id.state.data,
                                                            {
                                                                value: result.contact_id,
                                                                title: context.elements.passager_id.field.cmp.getValue()
                                                            }]
                                                    }, () =>
                                                        context.elements.passager_id.setValue(result.contact_id));
                                                }
                                            };
                                            f.setObjectData(params);
                                        },
                                        items: [{data: result.data}], replace: false
                                    });
                                };
                                f.setObjectData({
                                    context: context,
                                    tableName: 'contacts',
                                    id: 'null',
                                    values: formdata,
                                    data,
                                    // relation: {
                                    //     "tableName": "huntusers_contacts",
                                    //     "parentField": "contact_huntuser",
                                    //     rowId: context.state.data.route_huntuser,
                                    //     "relField": "huntuser_contact",
                                    //     "replace": false
                                    // },
                                    callback: submit
                                })
                            }
                        })
                    }
                }
                /*    source: {
                        tableName: 'zmu_contacts',
                        titleField: 'fio',
                        idField: 'contact_id',
                        filterString: "u_id is not null",
                    },*/
            },
            {
                pos: 180,
                row: 180,
                column_name: 'passed',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                is_list: true,
                data: [{value: true, title: 'принята'}, {value: false, title: 'не принята'}, {
                    value: null,
                    title: 'не рассмотрена'
                }],
                onChange: (event) => {
                    const {elements, newValue} = event;
                    //passed_date
                }
            },
            {
                pos: 190,
                row: 180,
                column_name: 'passed_date',
                disabled: () => context.state.data?.isvalid,
                width: '400px',
                labelAlign: 'left',
            },
            {
                pos: 200,
                row: 200,
                column_name: 'responsible_comment',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
            },
            {
                pos: 220,
                row: 220,
                column_name: 'accepted',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                is_list: true,
                data: [{value: true, title: 'принята'}, {value: false, title: 'не принята'}, {
                    value: null,
                    title: 'не рассмотрена'
                }],
            },
            {
                pos: 230,
                row: 220,
                column_name: 'accepted_date',
                disabled: () => context.state.data?.isvalid,
                width: '400px',
                labelAlign: 'left',
            },
            {
                pos: 240,
                row: 240,
                column_name: 'acceptor_comment',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
            },
            {
                pos: 205,
                row: 205,
                column_name: 'acceptor_id',
                disabled: () => context.state.data?.isvalid,
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'zmu_contacts',
                    titleField: 'fio',
                    idField: 'contact_id',
                    filterString: "15=any(roles)",
                },
            },
            {
                pos: 260, row: 260,
                column_name: 'isvalid', labelAlign: 'left', is_list: true,
                disabled: () => !f.isCommittee(),
                data: [{value: true, title: 'принята'},
                    {value: false, title: 'не принята'}, {
                        value: null,
                        title: 'не рассмотрена'
                    }],
            },
            {
                pos: 85,
                row: 75,
                column_name: 'erase_gpx',
                disabled: () => context.state.data?.isvalid,
                hidden: (context) => !(context.state.data?.erase),
                fieldset: 'gpx',
                data_type: 'path',
                labelAlign: 'left',
                cls: 'erase-gpx',
                path: (context) => `passages/`,
                column_label: 'трек затирки'
            },
            {
                pos: 86,
                row: 75,
                column_name: 'erase_gpx_upload',
                disabled: () => context.state.data?.isvalid,
                hidden: (context) => !(context.state.data?.erase),
                fieldset: 'gpx',
                data_type: 'upload',
                labelAlign: 'left'
            },
            {
                pos: 115,
                row: 120,
                column_name: 'passage_gpx', disabled: () => context.state.data?.isvalid,
                path: (context) => `passages/`,
                fieldset: 'gpx',
                data_type: 'path',
                labelAlign: 'left',
                cls: 'passage-gpx',
                column_label: 'трек учета'
            },
            {
                pos: 116,
                row: 120,
                column_name: 'passage_gpx_upload', disabled: () => context.state.data?.isvalid,
                fieldset: 'gpx',
                data_type: 'upload',
                labelAlign: 'left'
            }
        ]];
        context.subTables.push({
            column: 0, block: 0,
            pos: 0,
            title: 'Тесты',
            elementName: 'TestSubPanel',
            rowHeight: '100px',
            testName: 'passage',
            tableName: 'passages',
            readonly: () => context.state.data?.isvalid,
            hidden: () => !f.isCommittee(),
        });
        context.subTables.push({
            column: 0, pos: 15,
            title: 'Примечания',
            elementName: 'PassCommentSubPanel',
            idName: 'passage_id',
            rowHeight: '100px',
            filterString: "passage_id=$passage_id$",
            tableName: 'pass_comments_view',

        });
        if (f.isCommittee()) {
            context.subTables.push({
                title: 'ТАБЛИЦА 1. УЧЕТ СЛЕДОВ ЗВЕРЕЙ',
                column: 0,
                collapsed: true,
                elementName: 'PassageTable1Sub',
                tableName: 'PassageTable1Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
                readonly: () => context.state.data?.isvalid,
            });
            context.subTables.push({
                title: 'ТАБЛИЦА 2. УЧЕТ СЛЕДОВ В ДЕНЬ ЗАТИРКИ',
                column: 0,
                collapsed: true,
                elementName: 'PassageTable2Sub',
                tableName: 'PassageTable2Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
                readonly: () => context.state.data?.isvalid,
            });
            context.subTables.push({
                title: 'ТАБЛИЦА 3. УЧЕТ ПТИЦ',
                column: 0,
                collapsed: true,
                elementName: 'PassageTable3Sub',
                tableName: 'PassageTable3Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
                readonly: () => context.state.data?.isvalid,
            });
        }
        context.subTables.push(
            {
                title: 'Карта',
                fieldset: 'map',
                elementName: 'ZmuPasMapSub',
                tableName: 'passagelin',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
            });
        context.subTables.push({
            title: 'СЛЕДЫ ЗВЕРЕЙ',
            fieldset: 'animals',
            column: 0,
            editWidth: '90%',
            elementName: 'PointAnimalSub',
            tableName: 'animalspoints',
            idName: 'passage_id',
            filterString: "passage_id=$passage_id$",
            readonly: () => context.state.data?.isvalid,
        });
        context.subTables.push({
            title: 'УЧЕТ ПТИЦ',
            column: 0,
            fieldset: 'birds',
            editWidth: '90%',
            elementName: 'PointBirdSub',
            tableName: 'birdspoints',
            idName: 'point_id',
            filterString: "passage_id=$passage_id$",
            readonly: () => context.state.data?.isvalid,
            disabled: () => {
                return context.state.data?.use_transport_erase || context.state.data?.use_transport
            },
        });
        if (f.isCommittee()) {
            context.subTables.push({
                title: 'История изменений',
                fieldset: 'log',
                column: 0, pos: 100,
                elementName: 'PassLogSub',
                collapsed: true,
                noSingle: true,
                tableName: 'pass_log',
                subElementName: 'LogPage',
                filterString: "record_id = $passage_id$::varchar",
            })
        }
    }

    isClosed() {
        return this.props.appViewPort?.state?.zmu?.passage_closed;
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return (!context.isClosed()) && f.isEditRight(rights);
    }

    //есть ли права на добавление, добавлять нельзя
    isAddRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return (!context.isClosed()) && f.isAddRight(rights);
    }

    //есть ли права на удаление, удалять нельзя
    isDropRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return (!context.isClosed()) && f.isDropRight(rights);
    }

    //перетянуть данные из введенных точек
    addFrompointButton() {
        const context = this;
        const key = 'frompointButton';
        //если точки не заполнены, кнопки нет.
        const row = context.props.parent.grid.getCurrentRow();
        if (f.isCommittee())
            context.topMenuButtons.push(<BaseTextButton name={key} key={key} text={'Заполнить таблицы из точек'}
                                                        func={() => {
                                                            f.callPath({
                                                                context,
                                                                path: `zmu/passages/${context.rowId}/transfer`,
                                                                callback: (result) => {
                                                                    if (result.flash) {
                                                                        f.alert({
                                                                            title: 'Ответ сервера',
                                                                            message: `Данные не перенесены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                                                                        });
                                                                    } else {
                                                                        f.toast({
                                                                            title: 'Ответ сервера',
                                                                            message: 'Данные перенесены.',
                                                                            timeout: window.IasConfig.messageTimeout
                                                                        });
                                                                        if (context.getData) context.getData({context});
                                                                    }
                                                                    // if (context.grid) context.grid.getData({context:context.grid});
                                                                }
                                                            })
                                                        }}/>);
    }

    //сохранить редакцию
    onSave(params) {
        let {context, close} = params;
        let data = [];
        const saveFunc = () => {
            super.onSave({
                context,
                callback: (result) => {
                    const id = Number(result?.data?.layout_ea);
                    if (id)
                        f.callPath({
                            context: context.props.appViewPort,
                            path: `zmu/pas_ea/${id}/test`
                        });
                },
                close
            });
        };
        const saveTables = () => {
            Object.keys(context.elements).filter(e => context.elements[e].gridName).filter(e => context.elements[e].gridName.indexOf('Table') > -1 && context.elements[e]?.grid).map(e => {
                    const eldata = context.elements[e].grid.store.getData().items.map(i => i.data)
                    //.filter((r) => {
                    //return (r.spec_name1 && (r.count_bog1) || (r.count_fld1) || (r.count_fst1)) || (r.spec_name2 && (r.count_bog2) || (r.count_fld2) || (r.count_fst2))
                    //});
                    data = [...data, ...eldata];
                }
            );
            const callback = (result) => {
                saveFunc();
                if (result.flash) {
                    f.alert({
                        title: 'Ответ сервера',
                        message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                    });
                } else {
                    f.toast({
                        title: 'Ответ сервера',
                        message: 'Изменения сохранены.',
                        timeout: window.IasConfig.messageTimeout
                    });
                }
            };
            const prms = {
                pasId: context.rowId, data: data, callback
            };
            z.setPassTableData(prms);
        };
        const testRespFunc = () => {
            const responsible_id = context.elements.responsible_id.field.cmp.getValue();
            if (responsible_id && responsible_id.length > 4 && !Number(responsible_id)) {
                f.confirm({
                    timeout: 10000,
                    title: 'Нет записи.',
                    message: 'Введенного ответственного за ЗМУ нету в базе. Добавить новую запись в базу?'
                }, (result) => {
                    if (result) {
                        let formdata = new FormData();
                        const fio_post = responsible_id.split(',');
                        const values = fio_post[0].split(' ');
                        formdata.append('contact_active', true);
                        formdata.append('id', '-1');
                        formdata.append('family_name', values[0]);
                        formdata.append('first_name', values[1]);
                        formdata.append('second_name', values[2]);
                        const data = {
                            contact_active: true,
                            id: '-1',
                            family_name: values[0],
                            first_name: values[1],
                            second_name: values[2]
                        };
                        const submit = (result) => {
                            context.elements.responsible_id.field.cmp.setValue(result.data['contact_id']);
                            f.callRelationSend({
                                props: {
                                    relation: {
                                        "tableName": "huntusers_contacts",
                                        "parentField": "contact_huntuser",
                                        "relField": "huntuser_contact",
                                        rowId: context.state.data.route_huntuser,
                                        "relValue": [result.data.contact_id],
                                        "replace": false
                                    },
                                    idName: 'contact_id',
                                    rowId: context.state.data.route_huntuser,
                                },
                                callback: (res) => {
                                    const params = {
                                        context: context,
                                        tableName: 'huntusers_contacts',
                                        id: res.data.row_id,
                                        data: {post_in: fio_post[1] || 'Ответственный за ЗМУ', row_id: res.data.row_id},
                                        callback: () => {
                                            context.elements.responsible_id.setState({
                                                data: [...context.elements.responsible_id.state.data,
                                                    {
                                                        value: result.contact_id,
                                                        title: context.elements.responsible_id.field.cmp.getValue()
                                                    }]
                                            }, () =>
                                                context.elements.responsible_id.setValue(result.contact_id));
                                            saveTables();
                                        }
                                    };
                                    f.setObjectData(params);
                                },
                                items: [{data: result.data}], replace: false
                            });
                        };
                        f.setObjectData({
                            context: context,
                            tableName: 'contacts',
                            id: 'null',
                            values: formdata,
                            data,
                            // relation: {
                            //     "tableName": "huntusers_contacts",
                            //     "parentField": "contact_huntuser",
                            //     rowId: context.state.data.route_huntuser,
                            //     "relField": "huntuser_contact",
                            //     "replace": false
                            // },
                            callback: submit
                        })
                    } else {
                        f.alert({
                            title: 'Нет записи.',
                            message: 'Выберете учетчика из списка, или добавьте нового в базу.'
                        });
                    }
                })
            } else {
                saveTables();
            }
        };
        const testPassagerFunc = () => {
            const passager_id = context.elements.passager_id.field.cmp.getValue();
            if (passager_id && passager_id.length > 4 && !Number(passager_id)) {
                f.confirm({
                    timeout: 10000,
                    title: 'Нет записи.',
                    message: 'Введенного учетчика нету в базе. Добавить новую запись в базу?'
                }, (result) => {
                    if (result) {
                        let formdata = new FormData();
                        const fio_post = passager_id.split(',');
                        const values = fio_post[0].split(' ');
                        formdata.append('contact_active', true);
                        formdata.append('id', '-1');
                        formdata.append('family_name', values[0]);
                        formdata.append('first_name', values[1]);
                        formdata.append('second_name', values[2]);
                        const data = {
                            contact_active: true,
                            id: '-1',
                            family_name: values[0],
                            first_name: values[1],
                            second_name: values[2]
                        };
                        const submit = (result) => {
                            context.elements.passager_id.field.cmp.setValue(result.data['contact_id']);
                            f.callRelationSend({
                                props: {
                                    relation: {
                                        "tableName": "huntusers_contacts",
                                        "parentField": "contact_huntuser",
                                        "relField": "huntuser_contact",
                                        rowId: context.state.data.route_huntuser,
                                        "relValue": [result.data.contact_id],
                                        "replace": false
                                    },
                                    idName: 'contact_id',
                                    rowId: context.state.data.route_huntuser,
                                },
                                callback: (res) => {
                                    const params = {
                                        context: context,
                                        tableName: 'huntusers_contacts',
                                        id: res.data.row_id,
                                        data: {post_in: fio_post[1] || 'Проходчик', row_id: res.data.row_id},
                                        callback: () => {
                                            context.elements.passager_id.setState({
                                                data: [...context.elements.passager_id.state.data,
                                                    {
                                                        value: result.contact_id,
                                                        title: context.elements.passager_id.field.cmp.getValue()
                                                    }]
                                            }, () =>
                                                context.elements.passager_id.setValue(result.contact_id));
                                            testRespFunc();
                                        }
                                    };
                                    f.setObjectData(params);
                                },
                                items: [{data: result.data}], replace: false
                            });
                        };
                        f.setObjectData({
                            context: context,
                            tableName: 'contacts',
                            id: 'null',
                            values: formdata,
                            data,
                            // relation: {
                            //     "tableName": "huntusers_contacts",
                            //     "parentField": "contact_huntuser",
                            //     rowId: context.state.data.route_huntuser,
                            //     "relField": "huntuser_contact",
                            //     "replace": false
                            // },
                            callback: submit
                        })
                    } else {
                        f.alert({
                            title: 'Нет записи.',
                            message: 'Выберете учетчика из списка, или добавьте нового в базу.'
                        });
                    }
                })
            } else {
                testRespFunc();
            }
        };
        testPassagerFunc();
    }

}


