import React, {Component} from 'react';
import {ExtGrid, Panel} from '@sencha/ext-react-modern';

import * as f from "../common/Funcs";
import BaseSinglePage from "./BaseSinglePage";
import GridField from '../collections/GridField'
import {PageFilter} from "../collections/panelFilters";

const Ext = window['Ext'];
const keyName = `BaseGrid`;

export default class BaseGrid extends Component {

    static defaultProps = {
        data: [], vdata: [], pageSize: 50, rowHeight: 20, rowId: null
    }

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.rowNumberWidth = '50px';
        //требуемые параметры
        context.tableName = props.tableName;
        //ключевое поле
        context.idName = props.idName;
        //спрятать столбцы по списку
        context.hiddenColumns = props.hiddenColumns || [context.idName];
        //оформление гриды из клиента, объединится с ответом с сервера
        context.columnsDefaults = context.props.columnsDefaults || [];
        //развертка строки
        context.singleColumns = [];
        context.singleColumnsCount = 2;
        context.state = {
            vdata: (context.props.data) ? context.props.data : [],
            data: (context.props.data) ? context.props.data.slice(0, context.props.pageSize) : [],
            columns: [],
            title: 'Started',
            singleMode: false
        };
        const storeProps = {
            data: []
        };
        if (context.groupField) storeProps.groupField = context.groupField;
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

    componentDidMount = () => {
        const context = this;
        //     context.getData(context, context.props.parent.activeColumn);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.toArray(document.getElementsByClassName('copy-container')).map(t =>
            window.toArray(t.getElementsByClassName('copy-class x-gridcell ')).map(w =>
                window.toArray(w.getElementsByClassName('x-after-input-el')).map(r => r.onclick = (sender) => {
                    const message = sender.currentTarget.previousElementSibling.textContent;
                    f.copyToClipboard(message);
                    f.toast({title: 'скопированно', message: message, timeout: window.IasConfig.messageTimeout});
                })))
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const keysP = Object.keys(nextProps);
        const keysS = Object.keys(nextProps);
        const difP = keysP.filter(k => nextProps[k] != this.props[k] && this.props[k] != null);
        const difS = keysS.filter(k => (nextState[k] != this.state[k]) && (!(Array.isArray(nextState[k]) && Array.isArray(this.state[k]) && nextState[k].length != nextState[k].length)));
        if (difP.length > 0 || difS.length > 0) {
            return true;
        }
        return true;
    }

    //загрузить данные источника в грид
    loadPagingData(context, data) {
        // if (window.IasConfig.devMode) console.debug(`loadPagingData`);
        const dataCount = Math.ceil(data.length / context.props.pageSize);
        const loaddata = (!context.props.paging) ? data : data.slice(0, context.props.pageSize);
        context.store.loadRawData(loaddata, false);
    }

    //применить фильтры грида к данным и сбрасываем страницу
    applyFilter(context) {
        let data = context.state.vdata;
        let prevdata = context.store.data.items;
        const filters = context.store.getFilters().items;
        const sorters = context.store.getSorters().items;
        if (data) {
            filters.map(f => {
                data = data.filter(r => f.fn(r));
            });
            data.sort((a, b) => {
                //перебираем сортировки. если первая дает ==, применяем следующую
                return sorters.reduce((z, s) => {
                    if (z) return z;
                    const x = a[s.config.property];
                    const y = b[s.config.property];
                    const direction = (s.config.direction == 'ASC') ? 1 : -1;
                    return (x < y) ? -1 * direction :
                        (x > y) ? 1 * direction :
                            0;
                }, null);
            })

            const dataCount = Math.ceil(data.length / context.props.pageSize);
            if (JSON.stringify(data.map(d => Number(d[context.idName]))) == JSON.stringify(prevdata.map(d => Number(d[context.idName])))) return;
            if (context.paging) context.paging.setState({pageCurrent: 1, pageCount: dataCount});
            context.setState({data: data}, () =>
                context.loadPagingData(context, data)
            );
        }
    }

    getData(params) {
        const {context} = params;
        return false;
    }

    afterGetData(context, upData) {
        context.isLoaded = true;
        if (!upData.vdata || upData.vdata.length == 0) context.store.clearData();
        context.setState(upData, () => {
            context.applyFilter(context);
            context.store.on('filterchange', (store, filters, eOpts) => {
                context.applyFilter(context)
            });
            context.store.on('beforesort', (store, sorters, eOpts) => {
                context.applyFilter(context)
            });
        });
    }

    //удаление связи
    dropRecord(context) {
        const dropFunc = (tableName, id) => {
            f.dropObject(
                {
                    context: context.props.appViewPort, id: id, tableName: tableName,
                    callback:
                        (result) => {
                            if (window.IasConfig.devMode) console.debug(`${keyName} dropRecord dropped`, `result ${result}`);
                            //добавляем колонку редактирования, если её нету в списке и родитель выдал права на редактирование
                            context.getData({context});
                        }
                })
        };
        const id = context.getCurrentId();
        if (!id) return;
        let tableName = '';
        if (context.props.relation) {
            tableName = context.props.relation.tableName;
        } else if (context.props.parent.editForm) {
            tableName = context.props.parent.editForm.tableName;
        } else return;
        if (tableName == '') return;
        f.confirm({
            title: 'Удаление',
            message: `Вы действительно хотите удалить запись?`
        }, (e) => {
            if (e)
                dropFunc(tableName, id);
        });

    }

    //выделенная в таблице строка
    getFirstId() {
        if (window.IasConfig.devMode) console.debug("BaseGrid getFirstId");
        const context = this;
        try {
            return context.getFirstRow()[context.idName];
        } catch (err) {
            return null;
        }
    }

    //выделенная в таблице строка
    getFirstRow() {
        if (window.IasConfig.devMode) console.debug("BaseGrid getFirstRow");
        const context = this;
        try {
            return context.state.data[0];
        } catch (err) {
            return null;
        }
    }

    //выделенная в таблице строка
    getCurrentRow() {
        if (window.IasConfig.devMode) console.debug("BaseGrid getCurrentRow");
        const context = this;
        try {
            switch (context.store.getCount()) {
                //было закомментировано, проверить, почему. Может где-то вылезти глюк с выбором первой строки
                case 1:
                    return context.getFirstRow();
                case 0:
                    return null;
                default:
                    return context.grid.cmp.getSelection().getData();
            }
        } catch (err) {
            return null;
        }
    }

    //копирование выделенной записи

    //id выделенной строки
    getCurrentId() {
        if (window.IasConfig.devMode) console.debug("BaseGrid getCurrentId");
        try {
            return this.getCurrentRow()[this.idName];
        } catch (err) {
            return null;
        }
    }

    //перейти на страницу редактирования
    showEditPanel(context) {
        const id = context.getCurrentId();
        document.setCookie(context.tableName, id);
        context.props.parent.rowId = id;
        context.props.parent.setState({pageDefaults: []});
        context.props.parent.showEditPanel(context.props.parent);
    }

    hideEditPanel(context) {
        context.props.parent.setState({showEditPanel: false});
    }

    /*для таблицы связей*/
    getToolBar() {
        const context = this;

        return '';
    }

    getPaging() {
        const context = this;
        if (context.paging)
            return <Panel ref={(p => context.panel = p)} key={`${keyName}pagingPanel`}>
                <Paging ref={(p => context.paging = p)} key={`${keyName}paging`} parent={context}/>
            </Panel>
        else return '';
    }

    //сообщения о необходимости указания фильтров
    //params=[{filter:filter_name,message:'text'}]
    emptyMessages(params) {
        if (params && typeof (params) == 'string') {
            return <Panel key={keyName + 'filterInfo1'}
                          html={`<p><span key={keyName + 'span1'}>${params}</span></p>`}/>;
        } else {
            if (!params) params = this.props.parent.filters;
            if (!params) return '';
            if (!Array.isArray(params)) params = [params];
            let f = params.filter(p => p?.required && (!document.getCookie(`${p.name}Filter`))).reduce((q, p) => q + `<p><span key={keyName + 'span2'}>${p.message || p.name}</span></p>`, '');
            if (f.length == 0 && (this.inload)) f += `<p><span key={keyName + 'loadspan'}>Идет загрузка...</span></p>`;
            if (f != '') return (<Panel key={keyName + 'filterInfo2'} html={f}/>)
            else return null;
        }
    }

    getHeight() {
        return f.getCentralHeight() - ((!this.paging) ? 40 : 70);
    }

    //высота для печати
    getMaxHeight(context) {
        const itemsCount = context.store.getData().getCount();
        const result = (1 + itemsCount) * context.props.rowHeight;
        const height = context.getHeight();
        if (result > height) return result; else return height;
    }

    getSingle(context) {
        //тело таблицы
        // return <Panel key={'sigp'}>Test</Panel>;
        return <BaseSinglePage
            rowId={context.getFirstId()}
            tableName={context.tableName}
            disableSingleEdit={context.props.disableSingleEdit}
            ref={(s => {
                if (s) context.single = s;
            })}
            key={`${keyName}singleP`}
            columns={[]}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }

    getItemConfig(context) {
        return (context.tpl) ? {
            body: {
                tpl: context.tpl
            }
        } : {};

    }

    gridSelected(context, grid, selected) {

    }

    getGrid(context, columns) {
        const plugins = {
            gridfilters: true,
            gridexporter: true,
        };
        if (context.props.paging) plugins.pagingtoolbar = true;
        if (context.props.expanded) plugins.rowexpander = true;
        return <ExtGrid
            layout={'fit'}
            key={keyName + 'grid'}
            name={keyName}
            //            height={context.getHeight()}
            height={(context.props.height && context.props.height !== '100%') ? context.props.height : context.getHeight()}
            maxHeight={context.props.maxHeight || context.getMaxHeight(context)}
            maxWidth={context.props.maxWidth}
            width={context.props.width}
            minHeight={context.props.minHeight}
            minWidth={context.props.minWidth}
            rowHeight={context.props.rowHeight}
            variableHeights={false}
            rowNumbers={(context.props.noRN) ? null :
                {width: context.rowNumberWidth}}
            fullscreen={true}
            plugins={plugins}
            grouped={context.grouped}
            //                  groupFooter={context.groupFooter}
            // groupField={context.groupField}
            groupHeader={{
                tpl: context.groupHeaderTpl, onInitialize: (sender, eOpts) => {
                }
            }}
            itemConfig={context.getItemConfig(context)}
            store={context.store}
            ref={g => {
                context.grid = g;
            }}
            listeners={{
                columnmenucreated(grid, column, menu, eOpts) {
                    menu.on('beforeshow', (sender) => {
                        sender.items.items.map(i => {

                            if (i.id.indexOf('gridcolumnsmenu') > -1) i.setHidden(true);  //прячем меню колонок
                            f.localeMenu(i);
                        });
                    });
                },
                select(grid, selected) {
                    context.gridSelected(context, grid, selected);
                },
            }}
        >
            {columns}
        </ExtGrid>
    }

    getColumn(context, c) {
        Object.keys(c).map(k => {
            if (typeof (c[k]) == 'function' && ['func', 'renderer', 'handler', 'getColor', 'onChange'].indexOf(k) == -1) {
                c[k] = c[k](context);
            }
        })
        const hidden = (f.exist(c.hidden)) ? c.hidden :
            ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.column_name == 'order_value');
        return <GridField
            key={c.column_name}
            column_name={c.column_name}
            column_label={c.column_label}
            color_field={c.color_field}
            getColor={c.getColor}
            colored={c.colored}
            readonly={c.readonly}
            hidden={(hidden)}
            className={c.className}
            description={c}
            parent={context.props.parent}
            flex={c.flex}
            grid={context}
            relation={c.relation}
            title={c.title}
            align={c.align}
            ignoreExport={c.ignoreExport}
            groupHeaderTpl={(context.groupField == c.column_name) ? context.groupHeaderTpl : ''}
            editable={c.editable}
            data_type={c.data_type}
            minWidth={c.minWidth}
            maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
            ignorePrint={c.ignorePrint}
            onChange={c.onChange}
            renderer={c.renderer}
            func={c.func}
            summary={false}
            summaryRenderer={(x, scope, id, context, ...args) => {
                debugger;
            }}
        />
    }

    render() {
        const context = this;
        const columns = [];
        const content = [];
        const loadedtext = context.emptyMessages();
        if (loadedtext) content.push(loadedtext);
        if (context.isLoaded && !loadedtext) {
            if (context.state.singleMode) {
                content.push(context.getSingle(context));
            } else {
                if (context.state.columns) {
                    /*заполнение полей для store*/
                    context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
                    /*обработка ячейки с признаком редакции*/
                    context.state.columns.sort((a, b) => a.pos - b.pos).map(c => {
                        const column = context.getColumn(context, c);
                        columns.push(column);
                    })
                }
                if (columns.length > 0) {
                    content.push(context.getGrid(context, columns));
                    content.push(context.getPaging(context, columns));
                }
            }
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());
        return (
            <Panel key={`${keyName}gridpanel`} hidden={context.props.hidden}
                   ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            >{content}</Panel>
        )
    }
}

//страницы
export class Paging extends PageFilter {
    keyName = 'gridpaging'

    constructor(props) {
        super(props);
    }

    updatePage(context, nextState) {
        const parent = context.props.parent;
        const number = nextState.pageCurrent;
        if (window.IasConfig.devMode) console.debug(`updatePage `, nextState);
        parent.store.loadRawData(parent.state.data.slice((number - 1) * parent.props.pageSize, (number) * parent.props.pageSize));
    }
}

