import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import * as f from "../../common/Funcs";

const keyName = 'zmu.js';
export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    let color = [0, 0, 0, 1];
    const props = feature.getProperties();
    const source = props.source;
    let style, Point, LineString, Polygon;
    if (['routes', 'layout'].findIndex(i => source?.indexOf(i) > -1) > -1) {
        const active = props.route_active || (f.exist(props.date_off) &&
            (props.date_off == null || Number(props.date_off.substr(-4)) >= Number(document.getCookie('yearFilter'))));
        const pass_count = (!f.exist(props.pass_count) || !(props.pass_count == 0));
        if (active || pass_count) {
            color = [225, 0, 0, 1];
        } else {
            color = [50, 50, 50, 0.5];
        }
        if (props.type=='prepare') {
            color = [225, 50,0, 1];
        }
        if (props.type=='plan') {
            color = [0, 0,0, 1];
        }
        LineString = new Style({
            stroke: new Stroke({
                color: color,
                width: (props.important&&props.type!='prepare') ? 6 : (props.type!='plan') ? 2 : 1
            }),
            text: (props.type!='prepare') ?new Text({
                text: (resolution < 500) ? props.route_name || props.label : '', textBaseline: 'bottom',
                fill: new Fill({color: color}),
                font: `${window.IasConfig.map.fontSize} Tahoma`,
                placement: 'line'
            }):null
        });
        Point = new Style({
            image: new CircleStyle({radius: 5, fill: new Fill({color: color,}),}),
        });

    } else {
        let text = props.route_name || props.label;
        let width = 2;
        if (['passagelin', 'correct'].findIndex(i => source?.indexOf(i) > -1) > -1) {
            switch (props.lin_day) {
                case 0:
                    color = [225, 0, 0, 1];
                    break;
                case 1:
                    text = 'затирка';
                    width = 3;
                    color = [128, 128, 225, 1];
                    break;
                case 2:
                    text = 'учет';
                    width = 3;
                    color = [225, 0, 255, 1];
                    break;
            }
            LineString = new Style({
                stroke: new Stroke({
                    color: color,
                    width
                }),
                text: new Text({
                    text: (resolution < 500) ? text : '', textBaseline: 'bottom',
                    fill: new Fill({color: color}),
                    font: `${window.IasConfig.map.fontSize} Tahoma`,
                    placement: 'line'
                })
            });
        } else {
            if (props.type == 'vertex') {
                color=[100, 0,0, 1];
                Point = new Style({
                    image: new CircleStyle({
                        radius: 3,
                        fill: new Fill({color,}),
                    }),
                    text: new Text({
                        text: (resolution < 500) ? props.num  : '',
                        textBaseline: 'bottom',
                        offsetX: 5,
                        offsetY: -5,
                        fill: new Fill({color: color}),
                        font: `${window.IasConfig.map.fontSize} Tahoma`
                    })
                })
            }
            else {
                color = [0, 0, 0, 1];
                Point = new Style({
                    image: new CircleStyle({
                        radius: 5,
                        fill: null,
                        stroke: new Stroke({color: 'red', width: 1}),
                    }),
                });
                Polygon = new Style({
                    stroke: new Stroke({
                        color: [0, 150, 255, 1],
                        width: 2
                    })
                });
                LineString = new Style({
                    stroke: new Stroke({
                        color: color,
                        width: 2
                    }),
                    text: new Text({
                        text: props.route_name,
                        offsetX: 10,
                        offsetY: -10,
                        font: `${window.IasConfig.map.fontSize} Tahoma`,
                        fill: new Fill({color: color}),
                    })
                });
            }
        }
    }
    style = {
        'Point': Point,
        'Polygon': Polygon,
        'MultiPolygon': Polygon,
        'LineString': LineString
    };
    return style[feature.getGeometry().getType()];
}

