import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import * as f from "../../../common/Funcs";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import {Panel} from "@sencha/ext-react-modern";
import ZmuMap from "../../../Maps/ZMU/ZmuMap";
import {BaseButton} from "../../../Base/BaseButtons";
import * as g from "../../../common/gisFuncs";

const keyName = `ZmuLayoutPage`;
const title=`Зимний маршрутный учет. План схемы прохождений.`;
export default class ZmuLayoutPage extends BaseZmuPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now=new Date();
        context.paging = true;
        context.gridName="LayoutGrid";
        context.subElementName = 'RoutePage';
        context.tableName = 'layout';
        context.idName = 'row_id';
        context.topMenuButtonNames=['xls','html','reload','clipboard','save'];
        context.hiddenColumns=[context.idName];
        context.filters =[
            {name:'year',pos:10,required:true, message: f.locale('needyear')},
            {name:'users',pos:40},
            {name:'region',pos:30, gisSearch: true},
            {name:'it', pos:50,gisSearch: true},
            // {name:'page',pos:60}
        ];
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const s = context.state;
        if (prevState.accYear != s.accYear) {
            if (context.grid) context.grid.getData({context:context.grid});
        }
    }

    //кнопки для меню
    addSaveButton() {
        if (window.IasConfig.devMode) console.debug(`${keyName} addAdditButton`);
        const context = this;
        const key = 'savebutton';
        if (context.isEditRight()) {
            const saveFunc = () => {
                if (context.grid.grid.cmp) {
                    const modified = context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data);
                    const acc = document.getCookie('accFilter');
                    if (acc && modified.length > 0) {
                        const closeFunc = (result) => {
                            if (result.flash) {
                                f.alert({
                                    title: 'Ответ сервера',
                                    message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                                });
                            } else
                                f.toast({title: 'Ответ сервера', message: 'Изменения сохранены.', timeout: window.IasConfig.messageTimeout});
                        }
                        f.setAccsData({accName: acc, values: modified, callback: closeFunc})
                    }
                }
            }
            context.topMenuButtons.push(<BaseButton name={'saveButton'} key={'saveButton'} iconCls={'fa-save'}
                                                    func={saveFunc}/>);
        }
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = context.getGrid(context);
        const editMenu = context.getEditMenu(context);
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        // const eaType = context.getEaTypePanel(context,true);
        return (<Panel
            cls={'print-100-percent by-map-panel'}
            // height={f.getCentralHeight()}
            // region={"center"} margin="3"
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            <Panel docked={"left"} width={"50%"} key={'gridPanel'}
                   resizable={{
                       split: true,
                       edges: 'east',
                       dynamic: true
                   }}
                   listeners={{
                       resize:(element, info, eOpts)=>{
                           g.mapResize(context);
                       }
                   }}>
                {editMenu}
                {/*{eaType}*/}
                {grid}
                {dialog}
            </Panel>
            <Panel height={"100%"} key={'mapPanel'}>
                <ZmuMap key={'zmuMap'} parent={context} appViewPort={context.props.appViewPort} width={'100%'} styleFunction={context.styleFunction}
                        ref={(m => context.olMap = m)}/></Panel>
        </Panel>)
    }

}