import ErrorBoundary from "../ErrorBoundary";
import BaseEditPage from "../Base/BaseEditPage";
import * as f from "../common/Funcs";

export default class UserRolePage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'user_roles';
        context.idName = 'row_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsCount = 1;
        context.maximizeOnShow = false;
        context.fieldsSet = [
            {name: '1', title: 'Основная информация'},
        ];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'row_id', hidden: true, required: false},
            {
                column_name: 'role_user',
                disabled: true,
                hidden: true,
            },

            {
                column_name: 'user_role',
                is_list: true,
                source: {
                    tableName: 'roles_ref',
                    idField: 'role_id',
                    titleField: 'role_desc'
                },
                row: 0
            },
            {
                column_name: 'native_huntuser',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                    filterString: `active=true`
                },
                row: 1
            },
            {
                column_name: 'native_economy',
                is_list: true,
                source: {
                    tableName: 'economies',
                    titleField: 'economy_name',
                    idField: 'economy_id'
                },
                row: 2
            },
            {
                column_name: 'native_land',
                is_list: true,
                source: {
                    tableName: 'lands',
                    titleField: 'land_name',
                    idField: 'land_id',
                    filterString: `land_active=true`
                },
                row: 3
            },
            {
                column_name: 'native_region',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                },
                row: 4
            },
        ]];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        context.title = 'роль пользователя';
        return context.title;
    }
}


