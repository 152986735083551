import {Fill, Stroke, Style, Text} from "ol/style";
import * as f from "../../common/Funcs";

const keyName = 'ea.js';
export default (feature, resolution, context) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    let fillColor = [0, 0, 0, 1];
    let stokeColor = [0, 0, 0, 1];
    const props = feature.getProperties();
    let style;
    if (['routes', 'layout'].findIndex(i => props.source?.indexOf(i) > -1) > -1) {
        let width = (props.pass_count||1) * 2;
        let lineDash = (props.is_approved === false) ? [5] : null;
        if ((props.route_active || (f.exist(props.date_off) && props.date_off == null)) && (
            !(props.pass_count == 0)
        )) {
            stokeColor = [225, 0, 0, 1];
        } else {
            stokeColor = [50, 50, 50, 0.5];
        }

        style = new Style({
            stroke: new Stroke({
                color: stokeColor,
                width, lineDash
            }),
            text: new Text({
                text: (resolution < 500) ? props.route_name : '',
                font: `${window.IasConfig.map.fontSize} Tahoma`,
                maxAngle: 0,
                textBaseline: 'bottom',
                fill: new Fill({color: stokeColor}),
                placement: 'line'
            })
        });
    } else {
        if (['passages'].findIndex(i => props.source?.indexOf(i) > -1) > -1) {
            let width = props.pass_count * 2;
            let lineDash = null;
            switch (props.valid_state) {
                case true:
                    stokeColor = [225, 0, 0, 1];
                    break;
                case false:
                    stokeColor = [50, 50, 50, 0.5];
                    break;
                default:
                    stokeColor = [225, 0, 0, 1];
                    lineDash = [5];
            }
            style = new Style({
                stroke: new Stroke({
                    color: stokeColor,
                    width, lineDash
                }),
                text: new Text({
                    text: (resolution < 500) ? props.route_name : '',
                    font: `${window.IasConfig.map.fontSize} Tahoma`,
                    maxAngle: 0,
                    textBaseline: 'bottom',
                    fill: new Fill({color: stokeColor}),
                    placement: 'line'
                })
            });
        } else {
            if (props.source == 'eapol') {
                fillColor = document.getRGB(props.gid);
                stokeColor = fillColor;
                stokeColor[3] = 1;
            }
            if ((!props.object_ea))
                fillColor = [50, 50, 50, 0.2];
            else {
                fillColor = document.getRGB(props.object_ea);
                stokeColor = [0, 0, 0, 0];
            }
            style = new Style({
                fill: new Fill({
                    color: fillColor
                }),
                stroke: new Stroke({
                    color: stokeColor,
                    width: 2
                }),
                text: new Text({
                    text: (resolution < 500) ? props.label : '',
                    font: `${window.IasConfig.map.fontSize} Tahoma`,
                    fill: new Fill({color: [0, 0, 0, 1]}),
                })
            });
        }
    }
    return style;
}