import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';
import {Button, Panel, TextField, ToolTip} from "@sencha/ext-react-modern";
import * as f from "../common/Funcs";
import * as be from "../collections/baseElements";
import {BaseButton} from "../Base/BaseButtons";
import {manualLink} from "../common/siteFunctions";

const keyName = "TopPanel";

export default class TopPanel extends Component {


    getCabinet() {
        const context = this;
        const menu = [
            context.getProfile(),
            context.getLogOut(),
        ]

        const cabinet = React.createElement(be['amenu'], {
            name: 'userCabinet',
            key: 'userCabinet',
            style: {position: 'absolute', right: '200px'},
            text: document.getCookie('userfio'),
            parent: context,
            items: menu,
            appViewPort: context.props.appViewPort
        });
        return cabinet;
    }

    getLogOut() {
        const context = this;
        const loginFunc = `fetch('${window.IasConfig.homePath}users/logout', {credentials: 'include'}).then(res => {return res.json();}).then(
                        (result) => (callback)?callback(result):null,
                        (error) => {debugger;}
                    )`;
        const loginA = React.createElement(be['amenuitem'], {
            element_func: loginFunc,
            element_url: 'Login',
            name: 'exitUser',
            key: 'exitUser',
            text: 'Выход',
            parent: context,
            appViewPort: context.props.appViewPort
        });
        return loginA;
    }

    getProfile() {
        const context = this;
        const profileA = React.createElement(be['amenuitem'], {
            element_url: `${(f.getCurrentPage().toLowerCase().indexOf('zmu')>-1)?'Zmu':''}ProfilePage`,
            // element_url: `ProfilePage`,
            name: 'userProfile',
            key: 'userProfile',
            text: 'Профиль',
            parent: context,
            appViewPort: context.props.appViewPort
        });
        return profileA;
    }

    getMenuCallButton() {
        const props = this.props;
        const func = () => {
            props.appViewPort.setState({showMenu: !props.appViewPort.state.showMenu})
        };
        return (<BaseButton name={'callMenu'} key={'callMenu'} text={'Меню'}
                            iconCls={'fa-bars'}
                            func={func}/>);
    }

    getManualLink(type) {
        return <Panel layout={'vbox'} cls={'man-class'} key={'email_panel'}
        ><Button
            handler={() => {
                window.open(`${window.IasConfig.homePath}files/manuals/${type}_manual.pdf`)
            }}
            key={'manualload'}
            name={'manualload'}
            text={`Руководство ${(type == 'user' ? 'пользователя' : (type == 'admin') ? 'администратора' : ' пользователя ЗМУ')}`}
            ui={window.IasConfig.ui}
        /></Panel>
    }

    render() {
        const context = this;
        const isAuth = document.getCookie("isAuth");
        // const content = [context.getManualLink((context.props.parent.state.pageName.indexOf('Admin') > -1) ? 'admin' : 'user'), context.getMenuCallButton()];
        const content = [ context.getMenuCallButton()];
        context.props.content.map(i => content.push(i));
        if (isAuth) {
            /*кнопка выхода из сеанса. переделать на меню пользователя*/
            const cabinet = context.getCabinet();
            content.push(cabinet);
        }
        return <Panel
            id={keyName}
            key={keyName}
            cls={'no-print'}
            layout="hbox" shadow docked={"top"} pack={"start"}
            height={window.IasConfig.topHeight}
            margin={`0 0 ${window.IasConfig.margin} 0`}
            ref={(p => {
                if (!p) return;
            })}>
            {content}
        </Panel>
    }
}
