import BaseEditPage from "../../../Base/BaseEditPage";
import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";
import {CloseButton, DeleteButton, SaveButton, SaveCloseButton, ReloadButton} from "../../../common/Buttons";
import React from "react";
import {BaseTextButton} from "../../../Base/BaseButtons";
import {callRemoveBlanks} from "../../../common/Funcs";

const keyName = "EaPage";
export default class EaPage extends BaseEditPage {
    static defaultProps = {
        width: '98%',
        allowNull: true
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'explore_areas';
        context.testType = 'ea';
        context.blankType = 'empty_zmublank';
        context.zmuTableId = 'ea';
        context.idName = 'area_id';
        context.hiddenColumns = ['area_id', 'acc_year']
        context.columnsCount = [{width: '30'}, {width: '70', noOverflow: true}];
        context.footerButtonNames = ['closePlan', 'save', 'saveclose', 'delete', 'close'];
        context.topMenuButtonNames = ['print', 'reload', 'repeat', 'vedom', 'gpx', 'schema', 'routelist'];

        context.fieldsSet = [
            {
                title: 'Параметры исследуемой территории', fieldset: 'ea',
                column: 0, pos: 1, block: 1
            },
            {
                title: 'Требуемые протяженности', fieldset: 'min',
                column: 0, pos: 2, block: 2
            },
            {
                title: 'Протяженности', fieldset: 'l',
                column: 0, pos: 3, block: 3
            },
        ];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {'column_name': 'closed', hidden: true},
            {
                'column_name': 'area_id',
                hidden: true,
                'pos': 0,
                'row': 0,
                'fieldset': 'ea'
            },
            {
                'column_name': 'area_name',
                'pos': 1,
                'row': 0,
            },
            {
                'column_name': 'is_joint',
                hidden: false,
                defaultValue: false,
                'pos': 2,
                'row': 1,
            },
            {
                'column_name': 'acc_year',
                hidden: true,
                maxWidth: 75,
                defaultValue: () => Number(document.getCookie('yearFilter')),
                'pos': 0,
                'row': 0,
            },
            {
                column_name: 'in_calc',
                renderer: () => <label hidden={(!context.state.data.in_calc)}>
                    <span className={`attention`}>{`Идут расчеты по площадям и протяженности маршрутной сети.`}</span>
                </label>,
                defaultValue: true, row: 2, pos: 0
            },
            {
                'column_name': 'all_ga',
                disabled: true,
                'pos': 0,
                'row': 2,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
            },
            {
                'column_name': 'fst_ga',
                disabled: true,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                'pos': 1, 'row': 2
            },
            {
                'column_name': 'fld_ga',
                disabled: true,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                'pos': 2,
                'row': 2,
            },
            {
                'column_name': 'bog_ga',
                disabled: true,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                'pos': 3, 'row': 2
            },
            {
                'column_name': 'routes_count',
                labelAlign: 'left',
                disabled: true,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                'pos': 0,
                'row': 1,
            },
            {
                'column_name': 'min_l',
                fieldset: 'min',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 1,
                'row': 2,
            },
            {
                'column_name': 'min_fst',
                fieldset: 'min',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 2,
                'row': 2,
            },
            {
                'column_name': 'min_fld',
                fieldset: 'min',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 3,
                'row': 2,
            },
            {
                'column_name': 'min_bog',
                fieldset: 'min',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 4,
                'row': 2,
            },
            {
                'column_name': 'all_km',
                disabled: true,
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                fieldset: 'l',
                'pos': 0,
                'row': 3,
            },
            {
                'column_name': 'fst_km',
                fieldset: 'l',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 1, 'row': 3
            },
            {
                'column_name': 'fld_km',
                fieldset: 'l',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 2,
                'row': 3,
            },
            {
                'column_name': 'bog_km',
                fieldset: 'l',
                hidden: () => {
                    return Boolean(context.state.data.in_calc);
                },
                disabled: true,
                'pos': 3, 'row': 3
            }]];
        context.subTables = [
            {
                column: 0, block: 0,
                pos: 0,
                title: 'Тесты',
                elementName: 'TestSubPanel',
                testName: 'ea',
                tableName: 'ea',
            },
            {
                column: 1,
                block: 7,
                title: 'Карта',
                // elementName: 'BaseZmuMapSub',
                elementName: 'ZmuEaSingleMapSub',
                noOverflow: true,
                tableName: 'eapol',
                idName: 'ea_id',
                // activeColumn: 'date',
                filterString: "ea_id=$area_id$",
            },
            {
                column: 0,
                block: 4,
                title: 'Маршруты',
                tableName: 'earoutes',
                elementName: 'EaRoutesSub',
                filterString: "layout_ea=$area_id$",
            },
        ];
    }

    setAreaName = (context, areaName) => {
        if (context.elements.area_name) {
            context.elements.area_name.setValue(areaName);
        } else {
            context.columnsDefaults.filter(f => f.column_name == 'area_name')[0].defaultValue = areaName;
        }
    }

    addButton(buttonName, buttonText, blankName, fileExt = 'doc') {
        const context = this;
        const key = buttonName;
        if (window.IasConfig.devMode) console.debug(`${keyName} ${key}`);
        const callFunc = () => {
            let filters = f.getFilters('string');
            if (filters.indexOf('it=') == -1) filters += `&it=${context.rowId}`;
            if (filters.indexOf('zipName=') == -1) filters += `&zipname=${f.translit(context.state.data.area_name)}`;
            window.open(`${window.IasConfig.homePath}forms/pack/${blankName}/${fileExt}?${filters}`);

        };

        context.topMenuButtons.push(<BaseTextButton
            ui={window.IasConfig.ui}
            key={key}
            name={key}
            text={buttonText}
            func={callFunc}/>)
    }

    addSchemaButton() {
        this.addButton('schemabutton', 'схема ИТ', 'easchema');
    }

    addRoutelistButton() {
        this.addButton('routelistbutton', 'список маршрутов по ИТ', 'routes_ea','xls');
    }

    addVedomButton() {
        this.addButton('vedombutton', 'ведомости учета', 'empty_zmublank');
    }

    addGpxButton() {
        this.addButton('gpxbutton', 'треки', 'routegpx', 'gpx');
    }

    //кнопка reload общая для всех страниц
    addReloadButton() {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons.push(<ReloadButton label={'Обновить'} key={key} func={() => {
            context.update(context);
        }}/>)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (!context.props.parent.state.showEditPanel && context.updated) context.updated = false;
        if (context.props.parent.state.showEditPanel && !context.updated) {
            context.updated = true;
            if (context.elements?.ZmuEaSingleMapSub)
                context.elements.ZmuEaSingleMapSub.getData({context: context.elements.ZmuEaSingleMapSub});
        }
    }

    //есть ли права на запиcь
    isCloseRight() {
        const context = this;
        if (context.isNew) return false;
        const base = context.props.parent.isEditRight();
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return base && f.isZmuAdmin(rights);
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const app = context.props.appViewPort;
        let isEditRight = (app?.state?.zmu) && !app?.state?.zmu?.plan_closed;
        const rights = this.state.rights || this.props.rights;
        return isEditRight && f.isEditRight(rights) && !context.state.data.closed;
    }

    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const app = context.props.appViewPort;
        let isEditRight = (app?.state?.zmu) && !app?.state?.zmu?.plan_closed;
        const rights = this.state.rights || this.props.rights;
        return isEditRight && f.isAddRight(rights) && !context.state.data.closed;
    }

    //есть ли права на удаление
    isDropRight() {
        const context = this;
        const base = context.props.parent.isEditRight();
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return base && f.isDropRight(rights) && !context.state.data.closed;
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            if (record)
                context.title = `Исследуемая территория ${record['area_name']}`;
            else
                context.title = `Новая исследуемая территория`;
        } catch (e) {
        }
        return context.title;
    }

    //отправить на сервер
    setData(context, submit) {
        const geom = (context.olMap.geomField);
        const data = {
            ea_type: context.state.eaType,
            ea_name: context.elements.area_name.field.cmp.getValue(),
            geom: context.olMap.geomField,
            closed: context.elements.closed.field.cmp.getValue(),
            //objectsStore - передается из дочернего объекта
            list_obj: (!context.objectsStore) ? [] : context.objectsStore.getData().items.filter(i => i.data.object_ea == context.rowId).map(i => i.data.gid)
        };
        data.is_joint = (context.elements.is_joint.field.cmp.isChecked());
        //свой урл
        z.setEaData({
            context: context,
            id: (context.rowId),
            data: data,
            callback: (result) => {
                context.objectsStore.getData().items.filter(i => i.data.object_ea == -1).map(i => i.data.object_ea = result.data.area_id);
                if (submit) submit(result);
            }
        })
    }

    //кнопки в подвалеy
    getFooterButtons(buttons) {
        const context = this;
        const isClosed = context.state.data.closed;
        const result = [];
        buttons.map(b => {
            switch (b) {
                case 'closePlan':
                    if (context.isCloseRight()) {
                        result.push(<BaseTextButton name={'closeButton'}
                                                    text={(isClosed) ? 'Открыть для редактирования' : 'Закрыть для редактирования'}
                                                    func={() => {
                                                        context.elements.closed.setValue(!isClosed);
                                                        context.onSave({context});
                                                    }
                                                    }/>);
                    }
                    break;
                case 'save':
                    if (context.isEditRight())
                        result.push(<SaveButton key={`${b}Button`} func={() => context.onSave({context})}/>);
                    break;
                case 'saveclose':
                    if (context.isEditRight())
                        result.push(<SaveCloseButton key={`${b}Button`} text={`${b}Button`} func={() => {
                            context.onSave({context, close: true});
                            // context.onCancel(context);
                        }}/>);
                    break;
                case 'close':
                    result.push(<CloseButton key={`${b}Button`} text={`${b}Button`}
                                             func={() => context.onCancel(context)}/>);
                    break;
                case 'delete':
                    if (context.isDropRight())
                        result.push(<DeleteButton key={`${b}Button`} text={`${b}Button`}
                                                  func={() => context.deleteRecord(context)}/>);
                    break;
            }
        });
        return result;
    }

    onSave(params) {
        const context = this;
        const p = {...params};
        p.callback = (result) => {
            f.callRemoveBlanks('it', context.rowId, 'plan_zmu');
            if (params.callback) params.callback(result);
        }
        super.onSave(p);
    }
}


