import React,{Component} from "react";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import {Button, Panel, SegmentedButton} from "@sencha/ext-react-modern";
import * as z from "../../../common/zmuFuncs";
import * as f from "../../../common/Funcs";

const keyName = `ZmuPlanPage`;
const title=`Зимний маршрутный учет. Планирование учета.`;
export default class ZmuPlanPage extends BaseZmuPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now=new Date();
        context.filters =[{
            name: 'year',
            required: true,
            message: f.locale('needyear')
        }];
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }

    isEditRight() {
        const context = this;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        let isEditRight =(currentYear==context?.state?.accYear-1&&currentMonth<10)&&
            (context.props.appViewPort?.state?.zmu)&&!context.props.appViewPort?.state?.zmu?.plan_closed;
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');

        return isEditRight && f.isEditRight(rights);
    }

    onTypeChange(context, newValue, oldValue) {
        if (window.IasConfig.devMode) console.debug(window.funcName());
        f.confirm({
                title: 'Подтверждение действия',
                message: `Изменить принцип формирования Исследуемых Территорий на ${context.state.accYear} год? Все ранее сформированные Исследуемые территории на ${context.state.accYear} год будут удалены. `,
                type: 'confirm'
            },
            function (result) {
                if (result) {
                    window.mask();
                    const callback = (result) => {
                        window.unmask();
                        context.objectsGrid.setState({tableName: newValue});
                        context.grid.setState({eaType: newValue});
                    };
                    //задать новый параметр году. нет ссылки
                    z.changeEaType({context, eaType: newValue, accYear: context.state.accYear, callback})
                } else {
                    context.sb.cmp.setValue(oldValue);
                }
                return result;
            })
    }

    getEaTypePanel(context) {
        return <EaTypePanel key={'eatype'} parent={context} ref={(p => context.eapanel = p)}
                            eaType={context.state.eaType} changeFunction={context.onTypeChange}/>;
    }

    render() {
        const context = this;
        //панель "тип формирования"
        const eaTypePanel = context.getEaTypePanel(context);
        return eaTypePanel;
    }

}

export class EaTypePanel extends Component {

    //панель - принцип формирования
    render() {
        const props = this.props;
        const context = this.props.parent;
        if (f.getRoles()&&f.getRoles().filter(r=>r==10).length>0) {
            const closed = !context.isEditRight() || f.getRoles().filter(r => r == 10).length == 0;
            return <Panel
                key={'panelEaType'} name={'panelEaType'}
                title={'Принцип формирования'} ref={p => this.panel = p}>
                <SegmentedButton
                    key={'segment'}
                    layout={{
                        type: 'vbox',
                        vertical: true,
                        align: 'middle'
                    }}
                    disabled={closed}
                    ref={(sb => {
                        context.sb = sb;
                    })}
                >
                    <Button
                        ui={window.IasConfig.ui}
                        disabled={closed}
                        value="admpol" text={f.locale('admpol')} key={'admpol'} name={'admpol'}
                        handler={(sender) => {
                            props.changeFunction(context, sender.getValue(), 'landspol');
                        }}/>
                    <Button
                        ui={window.IasConfig.ui}
                        disabled={closed}
                        handler={(sender) => {
                            props.changeFunction(context, sender.getValue(), 'admpol');
                        }}
                        value="landspol" text={f.locale('landspol')} key={'landspol'} name={'landspol'}/>
                </SegmentedButton>
            </Panel>
        }
        else
            return ''
    }


}