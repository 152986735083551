import BasePageGrid from "../../Base/BasePageGrid";
const keyName='HuntLandsGrid';
export default class HuntLandsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.paging=true;
        context.idName = 'land_id';
        context.hiddenColumns=[context.idName,'eco_id','hu_id'];
        context.columnsDefaults = [
            {"column_name": "hu_name", pos: 1, minWidth:100, maxWidth: 200, flex: {grow: 2}},
            {"column_name": "lnd_name", pos: 2, minWidth:100,title:true, flex: {grow: 2}},
            {"column_name": "lnd_region", pos: 3, maxWidth: 300, minWidth: 100, flex: {grow: 2}},
            {"column_name": "lnd_active",column_label:'', pos: 0},
        ]

    }

}