import BaseEditPage from "../../../Base/BaseEditPage";
import * as f from "../../../common/Funcs";
import * as g from "../../../common/gisFuncs";
import {CloseButton, CopyButton, DeleteButton, PrintButton, SaveButton, SaveCloseButton} from "../../../common/Buttons";
import React from "react";
import {BaseTextButton} from "../../../Base/BaseButtons";

const keyName = "RoutePassedPage";
export default class    RoutePassedPage extends BaseEditPage {
    static defaultProps = {
        //minWidth:'900px',
        height: '95%',
        width: '90%',
        allowNull: true
    }

    /*Todo:
        Все изменения, если год учета не закрыт
        1. изменить имя, геометрию - если маршрут не утвержден,
        2. снять утверждение, если год фильтра=году начала
        3. утвердить - если не утвержден + год начала=году фильтра
        изменить год окончания - если фильтр года != году начала утвержден+год начала=фильтру+год не закрыт
       ограничить возможность закрытия маршрута текущим годом.
    проверка на закрытый год учета
*/
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'routes_view';
        context.testType = 'route';
        context.idField = 'row_id';
        context.columnsCount = [{width: '40'}, {width: '60'}];
        context.footerButtonNames = ['close'];
        context.fieldsSet = [
            {title: 'Параметры маршрута', fieldset: 'route', column: 0, pos: 1},
            {title: 'Населенный пункт', fieldset: 'locale', column: 0, pos: 2},
            {title: 'Протяженность', fieldset: 'length', column: 0, pos: 3},
            {title: 'Предварительное прохождение', fieldset: 'pre_length', column: 0, pos: 3},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {column_name: 'route_id', hidden: true},
            {
                column_name: 'route_name',
                required: true,
                pos: 0,
                row: 0,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: (context) => {
                    return (!context.isEditRight(context)) || (context.state.data?.is_approved);
                }
            },
            {
                column_name: 'oktmo', pos: 10, row: 5, disabled: true, labelMinWidth: '150px', labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                }
            },
            {
                column_name: 'route_huntuser',
                pos: 10,
                row: 6,
                disabled: true,
                labelMinWidth: '150px',
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                }
            },
            {
                column_name: 'route_land',
                pos: 10,
                row: 7,
                disabled: true,
                labelMinWidth: '150px',
                labelAlign: 'left',
                is_list: true,
                source: {
                    tableName: 'lands',
                    titleField: 'land_name',
                    idField: 'land_id',
                }
            },
            {
                column_name: 'year_on',
                disabled: true,
                pos: 10,
                row: 2,
                defaultValue: () => document.getCookie('yearFilter'),
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'year_off',
                pos: 20,
                row: 3,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: (context) => {
                    return (!context.isEditRight(context) || (!context.state.data?.is_approved))
                }
            },
            {
                column_name: 'is_approved',
                pos: 30,
                row: 4,
                labelMinWidth: '150px',
                labelAlign: 'left',
                disabled: true
            },
            {
                column_name: 'locality_name',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 7,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'locality_distance',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 8,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'locality_direction',
                fieldset: 'locale',
                disabled: true,
                pos: 40,
                row: 9,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'all',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'fst',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 10,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'fld',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 11,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'bog',
                fieldset: 'length',
                disabled: true,
                pos: 40,
                row: 12,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'length',
                fieldset: 'length',
                hidden: true,
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left'
            },
            {
                column_name: 'pre_all',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 13,
                labelMinWidth: '150px',
                labelAlign: 'left',
                hidden: (context) => (!f.isAdmin()),

            },
            {
                column_name: 'pre_fst',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 10,
                labelMinWidth: '150px',
                labelAlign: 'left',
                hidden: (context) => (!f.isAdmin()),

            },
            {
                column_name: 'pre_fld',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 11,
                labelMinWidth: '150px',
                labelAlign: 'left',
                hidden: (context) => (!f.isAdmin()),

            },
            {
                column_name: 'pre_bog',
                fieldset: 'pre_length',
                disabled: true,
                pos: 40,
                row: 12,
                labelMinWidth: '150px',
                labelAlign: 'left',
                hidden: (context) => (!f.isAdmin()),

            },
            {
                column_name: 'pre_path_upload', fieldset: 'pre_length',
                hidden: true,
            },
            {
                pos: 85,
                row: 75,
                fieldset: 'pre_length',
                column_name: 'pre_path',
                disabled: () => context.state.data?.isvalid,
                hidden: (context) => !(context.state.data?.erase),
                data_type: 'path',
                labelAlign: 'left',
                cls: 'route-gpx',
                path: (context) => `routes/`,
                button_label: 'трек',
                column_label: 'трек предварительного прохождения',
            },
            {column_name: 'geometry', row: 100, data_type: 'text', hidden: true}
        ]];
        context.subTables = [
            {
                column: 0,
                pos: 4,
                title: 'Тесты',
                elementName: 'TestSubPanel',
                testName:'passage',
                tableName: 'passages',
            },
            {
                column: 1,
                block: 7,
                title: 'Карта',
                elementName: 'ZmuRoutePasMapSub',
                tableName: 'correctlin',
                idName: 'route_id',
                // activeColumn: 'date',
                filterString: "route_id=$route_id$",
            }];
    }

    getCopyButton(context, result) {
        const b = 'copy';
        return result.push(<CopyButton key={`${b}Button`} text={`${b}Button`}
                                       func={() => context.copyRecord(context)}/>);
    }

    //кнопка экспорта в html
    addPrintButton() {
        const context = this;
        const key = 'printbutton';
        const openDoc = () => {
            const values=new FormData();
            values.append('template','passage');
            values.append('template_map','map');
            const pasid=context.props.parent.grid.getCurrentRow().passage_id;
            if (pasid) {
                values.append('pasid',pasid);
            }
            else {
                values.append('routeid',context.rowId);
            }
            const res=f.callFile({id:(pasid)?'single':'pack',path:'template/generate/',method:'POST',values});
            //const popup = window.open(`${window.IasConfig.homePath}template/generate/${params.type}`, '', 'width=,height=,resizable=no');
            //popup.blur();
            //window.focus();
        }
        context.topMenuButtons.push(<PrintButton key={key} func={() => {
            openDoc();
        }}/>);
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = `Маршрут ${record['route_name']}`;
        } catch (e) {
        }
        return context.title;
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isEditRight(rights);
    }

    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isAddRight(rights);
    }

    isApproved() {
        const context = this;
        return context.state.data?.is_approved;
    }

    //есть ли права на удаление
    isDropRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isDropRight(rights) && (context.state.data?.route_id);
    }

    //кнопки в подвале
    getFooterButtons(buttons) {
        const context = this;
        const result = [];
        let setDataFunc;
        buttons.map(b => {
            switch (b) {
                case 'save':
                    if ((!context.isApproved()) && context.isEditRight(context.state.rights))
                        result.push(<SaveButton key={`${b}Button`}
                                                func={() => {
                                                    if (context.rowId == -1)

                                                        context.onSave({
                                                            context, path: 'zmu/routes/create/', callback: () => {
                                                                context.saveGeometry(context);
                                                            }
                                                        })

                                                    else
                                                        context.saveGeometry({
                                                            context, callback: () => {
                                                                context.onSave({context, path: 'zmu/routes/update/'})
                                                            }
                                                        })
                                                }}/>);
                    break;
                case 'saveclose':
                    if ((!context.isApproved()) && context.isEditRight(context.state.rights))
                        result.push(<SaveCloseButton key={`${b}Button`} text={`${b}Button`} func={() => {
                            context.saveGeometry({context, callback:() => {
                                context.onSave({context, close: true, path: 'zmu/routes/update/'})
                            }})
                        }}/>);
                    break;
                case 'close':
                    result.push(<CloseButton key={`${b}Button`} text={`${b}Button`}
                                             func={() => context.onCancel(context)}/>);
                    break;
                case 'delete':
                    if (context.isDropRight(context.state.rights) && context.rowId && (!context.isApproved()))
                        result.push(<DeleteButton key={`${b}Button`} text={`${b}Button`}
                                                  func={() => context.deleteRecord(context, `zmu/routes/delete/`)}/>);
                    break;
                case 'copy':
                    /*!!test*/
                    if (context.isAddRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/copy/',
                                callback: submit
                            })
                        };
                        result.push(<CopyButton key={`${b}Button`} text={`${b}Button`}
                                                func={() => context.onSave({context, close: true, setDataFunc})}/>);
                    }
                    break;
                case 'approve':
                    if (context.isEditRight(context.state.rights) && (context.rowId != -1) && !(context.isApproved())) {
                        setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/approve/',
                                callback: submit
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                            // func={() => context.copyRecord(context)}/>);
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
                case 'open':
                    if ((context.state.data?.year_off == null) && context.isEditRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            f.callPath({
                                id: (context.rowId),
                                path: 'zmu/routes/open/',
                                callback: submit
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
                case 'end':
                    if ((context.state.data?.year_off == null) && f.isEditRight(context.state.rights) && (context.isApproved())) {
                        let setDataFunc = (context, submit) => {
                            let values = new FormData();
                            values.append('year_off', Number(document.getCookie('yearFilter')) - 1);
                            f.setObjectData({
                                context: context,
                                id: (context.rowId),
                                path: 'zmu/routes/update/',
                                values: values,
                                callback: submit
                            })
                        };
                        result.push(<BaseTextButton key={`${b}Button`} text={`${b}Button`}
                                                    func={() => context.onSave({context, setDataFunc})}/>);
                    }
                    break;
            }
        });
        return result;
    }

}


