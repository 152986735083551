import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs"

const keyName = `HuntLandPage`;

export default class HuntLandPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'lands_view';
        context.idName = 'land_id';
        context.titleName = 'lnd_name';
        context.columnsCount = [{width:'60'}, {width:'40'}];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.footerButtonNames = ['saveclose', 'save', 'child', 'close'];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {
                column_name: 'land_economy',
                // disabled: true,
                pos: 1, row: 1,
                minWidth:'70%',
                is_list: true,
                source: {
                    tableName: 'economies',
                    idField: 'economy_id',
                    titleField: 'economy_name',
                    filterString: `economy_huntuser in (select economy_huntuser from "structure".economies where economy_id=$land_economy$)`
                }
            },
            {column_name: "land_name", pos: 0, row: 0},
            {column_name: "label", pos: 1, row: 0},
            {
                column_name: "land_active", pos: 3, row: 0,
            },
            {
                column_name: 'land_type', pos: 4, row: 2,
                is_list: true,
                source: {
                    tableName: 'land_types',
                    idField: 'land_type_id',
                    titleField: 'land_type'
                }
            },
            {
                column_name: 'land_reason', pos: 5, row: 2,
                is_list: true,
                source: {
                    tableName: 'reasons',
                    titleField: 'reason',
                    idField: 'reason_id'
                }
            },
            {
                column_name: 'land_parent', pos: 6, row: 3,
                is_list: true,
                disabled: true,
                source: {
                    tableName: 'lands',
                    idField: 'land_id',
                    titleField: 'land_name'
                }
            },
            {
                column_name: 'land_region', pos: 6, row: 3,
                disabled: true,
                readonly:true,
                is_list: true,
                source: {
                    tableName: 'regions',
                    idField: 'oktmo',
                    titleField: 'region_name'
                }
            },
            {column_name: "land_area", pos: 7, row: 4,},
            {column_name: "gis_area", pos: 8, row: 4,},
            {column_name: "land_sa", pos: 9, row: 4,},
            {column_name: "actual_date", pos: 13, row: 5,},
            {column_name: "sa_area", pos: 10, row: 4,},
            {column_name: "actual_area", pos: 11, row: 4,},
            {column_name: "land_start_date", pos: 11, row: 5,},
            {column_name: "land_date_off", pos: 12, row: 5,},
        ]];
        context.subTables = [
            {
                column:0,
                pos:1,
                title: 'Контактные данные',
                elementName: 'ContactsSub',
                tableName: 'landcontacts',
                subElementName: 'ContactPage',
                activeColumn: 'contact_active',
                idName:'contact_id',
                relation: {
                    tableName: 'land_contacts',
                    parentField: 'contact_land',
                    relField: 'land_contact'
                },
                filterString: "contact_land=$land_id$",
            },
            {
                column:1,
                block:1,
                title: 'Границы',
                elementName: 'BaseMapSub',
                showAll: true,
                mapButtonNames:['upload','download'],
                tableName: 'landspol',
                geomType:'Polygon',
                idName:'land_id',
                // activeColumn: 'date',
                filterString: "land_id=$land_id$",
            },
            {
                column:0,
                title: 'Документы',
                elementName: 'LandDocumentsSub',
                tableName: 'landdocuments',
                relation: {tableName: 'land_documents', parentField: 'doc_land', relField: 'land_doc'},
                activeColumn: 'doc_active',
                subElementName: 'DocumentPage',
                filterString: "doc_land=$land_id$",
                idName: 'doc_id',
            },
            {
                column:0,
                title: 'Зоны охраны',
                elementName: 'BaseSubGridPanel',
                subName:'ZonesSub',
                tableName: 'landzones',
                noSingle:true,
                subElementName: 'ZonePage',
                activeColumn: 'zone_active',
                filterString: "zone_land=$land_id$",
                idName: 'zone_id',
                hiddenColumns: ['zone_id', 'zone_land'],
                columnsDefaults:[...context.lastUserColumns,...[{column_name:'zone_name',title:true}]]
            },
            {
                column:0,
                title: 'Категории среды обитания',
                elementName: 'BiotopsSubPanel',
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='lands' and record_id = $land_id$::varchar",
            }
        ]
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        const parentTitle=record?.hu_name||record?.user_name||parentRow?.economy_name||parentRow?.huntuser_name||'';
        context.title= parentTitle + ' участок ' + (record?['lnd_name']:f.locale(`new${context.tableName}`));
        return context.title;
    }

    recordChild(context) {
        const data = context.state.data;
        const id = data.huntuser_id;
        if (window.IasConfig.devMode) console.debug(`${keyName} recordChild`, id);
        document.setCookie(context.props.tableName, null);
        f.confirm({
            title: 'Действие',
            message: `Вы действительно хотите закрыть участок и  сгенерировать участок-наследника?`,
            datatype: 'date'
        }, (e) => {
            if (e)
                f.prompt({
                    title: 'Дата в формате dd.mm.yyyy',
                    message: 'Задайте дату образования нового участка'
                }, (e, value) => {
                    if (e) {
                        debugger;
                        const date = (new Date(value)).toLocaleDateString();
                        f.callPath({
                            context: context.props.appViewPort,
                            path: `api/data/land/${context.props.parent.rowId}/child/${date}`,
                            callback: (result) => {
                                context.props.parent.rowId = result.data[0].id;
                                context.props.parent.showEditPanel(context.props.parent, false, context.props.parent.rowId);
                            }
                        })
                    }
                });

        })
        //задаем предварительные значения для полей


        //}
    }

}


