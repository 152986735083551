import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BaseByMapGrid from "../../../Base/BaseByMapGrid";
import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";

const keyName = 'LayoutGrid';

export default class LayoutGrid extends BaseByMapGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.pageSize = 0;
        context.tableName = `layout`;
        context.columnsDefaults = [{
            column_name: "pass_count",
            className: 'no-copy',
            data_type: 'counting',
            null: 0,
            pos: 1,
            maxWidth: 80,
            minWidth: 80,
            getColor: (val) => (val > 0) ? [100, 100, 100, 1] : [100, 100, 100, 0.5],
            //функция вызывается при смене числа
            func: (record) => {
                if (record.data.ea_name == '' || record.data.pass_count > 5) return false;
                const params = {
                    data: {routeId: record.data.row_id, passCount: record.data.pass_count}
                };
                const props = context.props;
                //перерисовать на карте
                props.parent.olMap.changeFeatures([{
                    id: record.data.row_id,
                    pass_count: record.data.pass_count
                }, props.parent.olMap]);
                z.setRoutePlan(params);
                return true;
            }
        }, {
            column_name: "huntuser_name",
            className: 'no-copy',
            pos: 3,
            flex: {grow: 2}
        },
            {column_name: "route_name", pos: 2, className: 'no-copy', title: true, maxWidth: 100, minWidth: 100},
            {column_name: "region_name", className: 'no-copy', pos: 2, minWidth: 150, maxWidth: 150,}, {
                column_name: "route_active",
                pos: 0
            }, {"column_name": "geom"}]
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        const context = this;
        if (context.state.eaType != prevState.eaType) {
            context.getData({context});
            if (context.props.parent.editForm) context.props.parent.editForm.setState({eaType: context.state.eaType});
        }
    }

    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        context.store.loadRecords([]);
        context.inload = true;
        window.mask();
        f.getPageData({
            context: context.props.appViewPort,
            pageSize: context.pageSize,
            keyName: context.props.appViewPort.state.pageName,
            filtersOn: true,
            callback: (result) => {
                context.inload = false;
                context.isLoaded = true;
                result.columns = result.columns.map(c => {
                    if (context.columnsDefaults) {
                        const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                        c = {...c, ...def};
                    }
                    return c;
                }).sort((a, b) => {
                    return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                });
                const upData = {columns: result.columns, vdata: result.data, error: result.flash};
                context.afterGetData(context, upData);
                //заполнение карты пришедшими данными

                const olMap = context.props.parent.olMap;
                if (olMap) {
                    const features = result.data.filter(r => r.geom).map(r => {
                        const props = {...r};
                        delete props.geom;
                        props.gid = props['row_id'];
                        props.source = context.tableName;
                        delete props['row_id'];
                        return {
                            type: 'Feature',
                            properties: props,
                            geometry: JSON.parse(r.geom)
                        }
                    })
                    olMap.clearFeatures({context: olMap});
                    olMap.addFeatures({geoJson: features, context: olMap});
                }
                if (callback) callback(result);
                window.unmask();
            }
        })
    }

    getHeight() {
        return f.getCentralHeight() - ((!this.paging) ? 40 : 70);
    }
}