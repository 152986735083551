import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = 'HuntUserPage';
export default class HuntUserPage extends BaseEditPage {
    static defaultProps = {
        height: '95%',
        width: '95%'
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("HuntUserPage constructor", arguments);
        context.tableName = 'hunt_users_view';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.idName = 'huntuser_id';
        context.titleName = 'hu_name';
        context.fieldsSet = [
            {title: 'Описание', pos: 0},

        ];
        context.footerButtonNames = ['save', 'saveclose', 'child', 'close'];
        context.columnsCount = [{width: '60'}, {width: '40'}];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'huntuser_id', hidden: true},
            {column_name: 'user_number', row: 0, pos: 0, maxWidth: 100},
            {column_name: 'active', row: 0, pos: 2, defaultValue: true},
            {column_name: 'user_name', row: 0, pos: 1, cls: 'huntuser-full-name'},
            {
                column_name: 'full_name',
                row: 1,
                pos: 0,
                disabled: true,
                cls: 'huntuser-full-name',
                max_length: 2000,
                height: '40px'
            },
            {column_name: "label", pos: 2, row: 1},
            {
                column_name: 'parent_id', row: 2, pos: 0,
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                    filterString: "date_on<'$date_on$'"
                }
            },
            {column_name: 'date_on', row: 2, pos: 1, defaultValue: new Date()},
            {
                column_name: 'date_off', row: 2, pos: 2,
                onChange: (sender, record, newValue, oldValue) => {
                    if (record) record['active'] = (newValue == null);
                }
            },
        ]];
        context.subTables = [
            {
                title: 'Наименование и адрес',
                elementName: 'HuntDescSub',
                tableName: 'userdescs',
                activeColumn: 'desc_active',
                subElementName: 'HuntDescPage',
                filterString: "desc_huntuser=$huntuser_id$",
                idName: 'row_id',
                hiddenColumns: ['row_id', 'desc_huntuser'],
            },
            {
                title: 'Контактные данные',
                elementName: 'ContactsSub',
                tableName: 'usercontacts',
                activeColumn: 'contact_active',
                subElementName: 'ContactPage',
                relation: {
                    tableName: 'huntusers_contacts',
                    parentField: 'contact_huntuser',
                    relField: 'huntuser_contact'
                },
                filterString: "contact_huntuser=$huntuser_id$",
                idName: 'contact_id',
                hiddenColumns: ['contact_id', 'contact_huntuser', 'num'],
                relColumnsDefaults: [{column_name: 'fio', width: 500, maxWidth: 500, minWidth: 500}]
            },
            {
                title: 'Охотхозяйства и участки',
                elementName: 'LandsWithEcoSub',
                //если base елемент, надо указать таблицу
                tableName: 'landswitheconomy',
                noSingle: true,
                activeColumn: 'land_active',
                subElementName: 'HuntEconPage',
                filterString: "economy_huntuser=$huntuser_id$",
            },
            {
                title: 'Документы',
                elementName: 'HuntUserDocumentsSub',
                tableName: 'userdocuments',
                relation: {tableName: 'huntusers_documents', parentField: 'doc_huntuser', relField: 'huntuser_doc'},
                subElementName: 'DocumentPage',
                activeColumn: 'doc_active',
                filterString: "doc_huntuser=$huntuser_id$",
            },
            {
                title: 'Банковские реквизиты',
                elementName: 'BankPropsSub',
                tableName: 'bank_props',
                subElementName: 'BankPropsPage',
                filterString: "acc_user=$huntuser_id$",
            },
            {
                title: 'Коды',
                elementName: 'HuntCodesSub',
                tableName: 'usercodes',
                activeColumn: 'code_active',
                subElementName: 'HuntCodesPage',
                filterString: "codes_huntuser=$huntuser_id$",
                idName: 'row_id',
                hiddenColumns: ['row_id', 'codes_huntuser'],
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='hunt_users' and record_id = $huntuser_id$::varchar",
            }
        ];

    }

    recordChild(context) {
        const data = context.state.data;
        const id = data.huntuser_id;
        if (window.IasConfig.devMode) console.debug(`${keyName} recordChild`, id);
        document.setCookie(context.props.tableName, null);
        f.confirm({
            title: 'Действие',
            message: `Вы действительно хотите закрыть действие данного юр. лица и сгенерировать юр.лицо-наследника?`,
            datatype: 'date'
        }, (e) => {
            if (e)
                f.prompt({
                    title: 'Дата в формате dd.mm.yyyy',
                    message: 'Задайте дату образования нового юр. лица'
                }, (e, value) => {
                    if (e) {
                        const date = (new Date(value)).toLocaleDateString();
                        f.callPath({
                            context: context.props.appViewPort,
                            path: `api/data/huntuser/${context.props.parent.rowId}/child/${value}`,
                            callback: (result) => {
                                context.props.parent.rowId = result.data[0].id;
                                context.props.parent.showEditPanel(context.props.parent, false, context.props.parent.rowId);
                            }
                        })
                    }
                });

        })
        //задаем предварительные значения для полей


        //}
    }

}


